var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "1200" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#1C3144" } },
        [
          _c(
            "v-btn",
            {
              attrs: { dark: "", icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("update:value", false)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("v-toolbar-title", { staticClass: "white--text" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              !_vm.isExistingFeature && !_vm.isBulkEdit
                ? _c("v-autocomplete", {
                    staticClass: "mt-7",
                    attrs: {
                      "auto-select-first": "",
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      items: _vm.selectedAds,
                      "item-text": "adName",
                      "item-value": "adId",
                      autocomplete: "off",
                      "menu-props": { maxHeight: 100 },
                      placeholder: "Select Ad"
                    },
                    on: { change: _vm.selectAd },
                    model: {
                      value: _vm.feature.adId,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "adId", $$v)
                      },
                      expression: "feature.adId"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "ma-0 pa-6" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      items: _vm.featureTypes,
                      label: "Feature Type",
                      "item-text": "description",
                      "item-value": "id",
                      "return-object": "",
                      autocomplete: "off",
                      "menu-props": {
                        bottom: true,
                        offsetY: true,
                        maxHeight: 220
                      }
                    },
                    model: {
                      value: _vm.feature.featureType,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featureType", $$v)
                      },
                      expression: "feature.featureType"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      items: _vm.pages,
                      label: "Page Number",
                      "item-text": "description",
                      "return-object": "",
                      "item-value": "id",
                      autocomplete: "off",
                      "menu-props": {
                        bottom: true,
                        offsetY: true,
                        maxHeight: 220
                      }
                    },
                    model: {
                      value: _vm.feature.page,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "page", $$v)
                      },
                      expression: "feature.page"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      items: _vm.departments,
                      label: "Departments",
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                      autocomplete: "off",
                      "menu-props": {
                        bottom: true,
                        offsetY: true,
                        maxHeight: 220
                      }
                    },
                    model: {
                      value: _vm.feature.department,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "department", $$v)
                      },
                      expression: "feature.department"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Brand / Combo",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.featDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featDesc", $$v)
                      },
                      expression: "feature.featDesc"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "More Text",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.featDesc2,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featDesc2", $$v)
                      },
                      expression: "feature.featDesc2"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Selected Varieties",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.featDesc3,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featDesc3", $$v)
                      },
                      expression: "feature.featDesc3"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Dimensions",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.featDesc4,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featDesc4", $$v)
                      },
                      expression: "feature.featDesc4"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      "auto-select-first": "",
                      "background-color": "#fff",
                      dense: "",
                      outlined: "",
                      items: _vm.manufacturers,
                      label: "Manufacturers",
                      "item-text": "description",
                      "item-value": "id",
                      "return-object": "",
                      autocomplete: "off",
                      "menu-props": {
                        bottom: true,
                        offsetY: true,
                        maxHeight: 220
                      }
                    },
                    model: {
                      value: _vm.feature.manufacturer,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "manufacturer", $$v)
                      },
                      expression: "feature.manufacturer"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Lump Sum",
                      placeholder: "0.00",
                      type: "number",
                      step: "0.01",
                      value: "0.00",
                      min: "0",
                      prefix: "$",
                      autocomplete: "off"
                    },
                    on: {
                      change: function($event) {
                        return _vm.formatCurrencyInput(
                          "lumpSum",
                          _vm.feature.lumpSum
                        )
                      }
                    },
                    model: {
                      value: _vm.feature.lumpSum,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "lumpSum", $$v)
                      },
                      expression: "feature.lumpSum"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Count",
                      placeholder: "0",
                      type: "number",
                      autocomplete: "off"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "append-outer",
                        fn: function() {
                          return [
                            _c(
                              "v-icon",
                              {
                                staticClass: "pb-6 mx-2",
                                attrs: { color: "primary" }
                              },
                              [_vm._v("mdi-at")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.feature.retCt,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "retCt", $$v)
                      },
                      expression: "feature.retCt"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Ad Retail",
                      placeholder: "0.00",
                      type: "number",
                      step: "0.01",
                      min: "0",
                      prefix: "$",
                      autocomplete: "off"
                    },
                    on: {
                      change: function($event) {
                        return _vm.formatCurrencyInput(
                          "retail",
                          _vm.feature.retail
                        )
                      }
                    },
                    model: {
                      value: _vm.feature.retail,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "retail", $$v)
                      },
                      expression: "feature.retail"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "Deal ID",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.dealIdent,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "dealIdent", $$v)
                      },
                      expression: "feature.dealIdent"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: _vm.isExistingFeature || _vm.isBulkEdit ? "12" : "8"
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled:
                        !_vm.isAdSelected &&
                        !_vm.isExistingFeature &&
                        !_vm.isBulkEdit,
                      dense: "",
                      "background-color": "#fff",
                      outlined: "",
                      label: "General Notes",
                      autocomplete: "off"
                    },
                    model: {
                      value: _vm.feature.featNotes,
                      callback: function($$v) {
                        _vm.$set(_vm.feature, "featNotes", $$v)
                      },
                      expression: "feature.featNotes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "#fafafa" } },
        [
          _c("v-spacer"),
          _vm.isExistingFeature
            ? _c(
                "v-btn",
                {
                  staticClass: "white--text mr-2",
                  attrs: { color: "red", height: "40" },
                  on: {
                    click: function($event) {
                      _vm.confirmDialog = true
                    }
                  }
                },
                [
                  _vm._v(" Delete"),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-trash-can-outline")
                  ])
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                disabled:
                  !_vm.isAdSelected &&
                  !_vm.isExistingFeature &&
                  !_vm.isBulkEdit,
                color: "green",
                height: "40"
              },
              on: {
                click: function($event) {
                  _vm.isExistingFeature || _vm.isBulkEdit
                    ? _vm.updateFeature()
                    : _vm.saveFeature()
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isExistingFeature || _vm.isBulkEdit ? "Update" : "Save"
                  ) +
                  " "
              ),
              _c("v-icon", { attrs: { right: "" } }, [
                _vm._v(
                  _vm._s(
                    _vm.isExistingFeature
                      ? "mdi-update"
                      : "mdi-checkbox-marked-circle-outline"
                  )
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("ConfirmDialog", {
        attrs: {
          value: _vm.confirmDialog,
          action: "Delete",
          type: "Feature",
          remove: _vm.removeFeature
        },
        on: {
          "update:value": function($event) {
            _vm.confirmDialog = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }