<template>
  <v-dialog :value="value" persistent width="1200">
    <v-toolbar flat color="#1C3144">
      <v-btn dark icon @click="$emit('update:value', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="white--text">
        {{title}}
      </v-toolbar-title>
      <v-spacer/>
      <v-col cols="4">
        <v-autocomplete class="mt-7" v-if="!isExistingFeature && !isBulkEdit" auto-select-first dense background-color="#fff" outlined
          :items="selectedAds"  item-text="adName" item-value="adId" v-model="feature.adId" @change="selectAd"
          autocomplete="off" :menu-props="{ maxHeight: 100 }" placeholder="Select Ad">
        </v-autocomplete>
      </v-col>
    </v-toolbar>
    <v-container class="ma-0 pa-6">
      <v-row dense>
        <v-col cols="5">
          <v-autocomplete :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" auto-select-first background-color="#fff" dense outlined
            :items="featureTypes" label="Feature Type" item-text="description" item-value="id" return-object
            v-model="feature.featureType" autocomplete="off" :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }">
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-autocomplete :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" auto-select-first background-color="#fff" dense outlined :items="pages"
            label="Page Number" item-text="description" return-object item-value="id" v-model="feature.page"
            autocomplete="off" :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }">
          </v-autocomplete>
        </v-col>
        <v-col cols="5">
          <v-autocomplete :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" auto-select-first background-color="#fff" dense outlined
            :items="departments" label="Departments" item-text="name" item-value="id" return-object
            v-model="feature.department" autocomplete="off" :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Brand / Combo"
            v-model="feature.featDesc" autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="More Text"
            v-model="feature.featDesc2" autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Selected Varieties"
            v-model="feature.featDesc3" autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Dimensions"
            v-model="feature.featDesc4" autocomplete="off">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <v-autocomplete :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" auto-select-first background-color="#fff" dense outlined
            :items="manufacturers" label="Manufacturers" item-text="description" item-value="id" return-object
            v-model="feature.manufacturer" autocomplete="off" :menu-props="{ bottom: true, offsetY: true, maxHeight: 220 }">
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Lump Sum" placeholder="0.00"
            type="number" step="0.01" value="0.00" min="0" prefix="$" v-model="feature.lumpSum" autocomplete="off"
            @change="formatCurrencyInput('lumpSum', feature.lumpSum)">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Count" placeholder="0"
            type="number" v-model="feature.retCt" autocomplete="off">
            <template v-slot:append-outer>
              <v-icon color="primary" class="pb-6 mx-2">mdi-at</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Ad Retail" placeholder="0.00"
            @change="formatCurrencyInput('retail', feature.retail)" type="number" step="0.01" min="0" prefix="$" v-model="feature.retail"
            autocomplete="off">
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="Deal ID"
            v-model="feature.dealIdent" autocomplete="off">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col :cols="isExistingFeature || isBulkEdit ? '12' : '8'">
          <v-text-field :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" dense background-color="#fff" outlined label="General Notes"
            v-model="feature.featNotes" autocomplete="off">
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-divider/>
    <v-toolbar flat color="#fafafa">
      <v-spacer/>
      <v-btn v-if="isExistingFeature" color="red" class="white--text mr-2" height="40" @click="confirmDialog=true">
        Delete<v-icon right>mdi-trash-can-outline</v-icon>
      </v-btn>
      <v-btn :disabled="!isAdSelected && !isExistingFeature && !isBulkEdit" class="white--text" color="green" height="40"
        @click="isExistingFeature || isBulkEdit ? updateFeature() : saveFeature()">
        {{isExistingFeature || isBulkEdit ? 'Update' : 'Save'}}
        <v-icon right>{{isExistingFeature ? 'mdi-update' : 'mdi-checkbox-marked-circle-outline'}}</v-icon>
      </v-btn>
    </v-toolbar>

    <ConfirmDialog :value.sync="confirmDialog" :action="'Delete'" :type="'Feature'" :remove="removeFeature" />

  </v-dialog>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash'
import { notification } from '@/mixins/notification'

export default {
  name: 'Feature',

  mixins: [notification],

  props: [
    'getAdFeatures',
    'isBulkEdit',
    'selected',
    'selectedAds',
    'selectedFeature',
    'value'
  ],

  components: {
    ConfirmDialog: () => import('@/components/shared/confirm-dialog')
  },

  data () {
    return {
      ads: [],
      confirmDialog: false,
      departments: [],
      featureTypes: [],
      isAdSelected: false,
      manufacturers: [],
      pages: [],
      type: 'Feature'
    }
  },

  computed: {
    title () {
      if (this.isBulkEdit) {
        return 'Feature Bulk Edit'
      } else if (!isEmpty(this.selectedFeature)) {
        return this.selectedFeature.adName
      } else {
        return 'Add Feature'
      }
    },

    isExistingFeature () {
      return !isEmpty(this.selectedFeature)
    },

    feature: {
      get: function () {
        return cloneDeep(this.selectedFeature)
      },
      set: function (feature) {
        return feature
      }
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.isAdSelected = false
        this.getFeatureOptions()
        if (value && this.isExistingFeature) {
          const values = {
            lumpSum: this.feature.lumpSum,
            retail: this.feature.retail
          }
          for (const key in values) {
            this.formatCurrencyInput(key, values[key])
          }
        } else if (value && !this.isExistingFeature && !this.isBulkEdit) {
          this.feature.featDesc3 = 'Selected Varieties'
          this.feature.dealIdent = 'ADVRD1'
          this.feature.lumpSum = 0
          this.formatCurrencyInput('lumpSum', this.feature.lumpSum)
        } else if (value && this.isBulkEdit) {
          this.feature = {}
        }
      }
    }
  },

  methods: {
    async getFeatureOptions () {
      const departments = this.$ads.getDepartments(this.isExistingFeature ? this.feature.supplier.id : this.selectedAds[0].supplierCode)
      const featureTypes = this.$ads.getAdFeatureTypes()
      const manufacturers = this.$ads.getManufacturers()
      const pages = this.$ads.getPages()

      await Promise.all([departments, featureTypes, manufacturers, pages])
        .then(response => {
          this.departments = response[0]
          this.featureTypes = response[1]
          this.manufacturers = response[2]
          this.pages = response[3]
        })
    },

    selectAd (ad) {
      ad ? this.isAdSelected = true : this.isAdSelected = false
    },

    async getDepartments (supplierID) {
      await this.$ads.getDepartments(supplierID)
        .then(response => {
          this.departments = response
        })
    },

    formatCurrencyInput (key, value) {
      this.feature[key] = ((value * 100) / 100).toFixed(2)
      this.$forceUpdate()
    },

    async updateFeature () {
      if (this.isBulkEdit) {
        const promises = []
        const errors = []

        this.showLoader('Updating Features', true)

        this.selected.forEach(feature => {
          for (const key in this.feature) {
            if (!Object.prototype.hasOwnProperty.call(key, feature)) {
              feature[key] = this.feature[key]
            }
          }

          promises.push(
            this.$ads.updateAdFeature(feature)
              .then(() => {}).catch(() => {
                errors.push(`${feature.adName} - ${feature.featureType.name} Not Updated`)
              })
          )
        })

        await Promise.allSettled(promises)
        if (errors.length) {
          this.showErrors(errors)
        } else {
          this.notify('success', 'Features Updated')
          this.$emit('update:value', false)
        }
      } else {
        await this.$ads.updateAdFeature(this.feature)
        .then(() => {
          this.notify('success', 'Feature Successfully Updated')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Feature Not Updated')
        })
      }
      this.getAdFeatures()
    },

    async saveFeature() {
      this.feature.billVendor = true
      this.feature.lumpSum = 'lumpSum' in this.feature && this.feature.lumpSum ? this.feature.lumpSum : 0

      await this.$ads.saveAdFeature(this.feature)
        .then(() => {
          this.notify('success', 'Feature Successfully Saved')
          this.$emit('update:value', false)
        }).catch(() => {
          this.notify('error', 'Feature Not Saved')
        })
      this.getAdFeatures()
    },

    async removeFeature () {
      await this.$ads.deleteAdFeature(this.feature)
        .then(() => {
          this.notify('success', 'Feature Successfully Deleted')
        }).catch(() => {
          this.notify('error', 'Feature Not Deleted')
        })
      this.getAdFeatures()
      this.$emit('update:value', false)
    }
  }
}
</script>
